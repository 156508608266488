import { ChakraProvider ,extendTheme  } from "@chakra-ui/react";
import React, { createContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Menu from "./pages/menu/Menu";
import Home from "./pages/home/Home";
import NoInternet from "./pages/errors/NoInternet";
import Attributes from "./pages/attributes/Attributes";
import Categories from "./pages/categories/Categories";
import Products from "./pages/product/Products";
import EditProducts from "./pages/product/EditProducts";
import Login from "./pages/auth/Login";
import Users from "./pages/users/Users";
import {  MultiSelectTheme } from 'chakra-multiselect'
import AddProduct from "./pages/product/AddProduct";
import { PhotoProvider } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import SubCategories from "./pages/categories/SubCategories";

export const ModeContext = createContext(null);
export default function App() {
  const [dark, setDark] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    function handleOnlineStatusChange() {
      setIsOnline(window.navigator.onLine);
    }

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);


const theme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme
  }
})
  return (
    <>
      {isOnline ? (
        <section className="font-fiexen">
          <ModeContext.Provider value={{ dark, setDark }}>
            <ToastContainer />
            <ChakraProvider theme={theme}>
            <PhotoProvider>
              <Router>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Home/>}
                  />
                  <Route
                    exact
                    path="/menu"
                    element={<Menu/>}
                  />
                  <Route
                    exact
                    path="/users"
                    element={<Users/>}
                  />
                  <Route
                    exact
                    path="/attributes"
                    element={<Attributes/>}
                  />
                  <Route
                    exact
                    path="/categories"
                    element={<Categories/>}
                  />
                  <Route
                    exact
                    path="/subCategories"
                    element={<SubCategories/>}
                  />
                  <Route
                    exact
                    path="/addProduct"
                    element={<AddProduct />}
                  />
                  <Route
                    exact
                    path="/products"
                    element={<Products />}
                  />
                  <Route
                    exact
                    path="/editProduct/:slug"
                    element={<EditProducts />}
                  />
                  <Route
                    exact
                    path="/login"
                    element={<Login />}
                  />
                </Routes>
              </Router>
              </PhotoProvider>
            </ChakraProvider>
          </ModeContext.Provider>
        </section>
      ) : (
        <NoInternet />
      )}
    </>
  );
}
