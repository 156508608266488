import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import GetAPI from "../../utilities/GetAPI";
import { PostAPI } from "../../utilities/PostAPI";
import { inputStyle, labelStyle } from "../../utilities/Input";
import { error_toaster, success_toaster } from "../../utilities/Toaster";
import { useNavigate } from "react-router-dom";
import Loader, { MiniLoader } from "../../components/Loader";
import { MultiSelect } from "chakra-multiselect";

export default function AddProduct() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("loginStatus")) {
      localStorage.removeItem("loginStatus");
      navigate("/login");
    }
  }, [navigate]);
  const { data } = GetAPI("category/view");
  const getSubCategory = GetAPI("category/sub/view");
  const [type, setType] = useState("pro");
  const attributeData = GetAPI("attribute/view");
  const attribute = [];
  attributeData.data?.data?.map((item, index) =>
    attribute.push({
      attribute: item.name,
      value: item.name,
    })
  );
  const [loader, setLoader] = useState(false);
  const [addProduct, setAddProduct] = useState({
    name: "",
    description: "",
    categoryId: "1",
    subCategoryId: "",
    price: "",
    discount: "",
    downloadLink: "",
    attributes: [],
    thumbnail: "",
    images: [],
  });
  const handleAttributeChange = (selectedAttributes) => {
    setAddProduct((prevProduct) => ({
      ...prevProduct,
      attributes: selectedAttributes,
    }));
  };

  const onChange = (e) => {
    setAddProduct({ ...addProduct, [e.target.name]: e.target.value });
  };

  let subCatCheck = getSubCategory?.data?.data?.filter(
    (prod) =>
      prod?.category?.id ===
      parseInt(addProduct.categoryId === "" ? 1 : addProduct.categoryId)
  );

  const addProductfunc = async (e) => {
    e.preventDefault();
    if (addProduct.name === "") {
      error_toaster("Please enter Product Name");
    } else if (addProduct.description === "") {
      error_toaster("Please enter Product Description");
    } else if (type === "") {
      error_toaster("Please Select Type");
    } else if (addProduct.categoryId === "") {
      error_toaster("Please enter Product category");
    } else if (addProduct.downloadLink === "") {
      error_toaster("Please enter Product Link");
    } else if (addProduct.thumbnail === "") {
      error_toaster("Please enter thumbnail");
    } else {
      setLoader(true);
      const formData = new FormData();
      const product = {
        name: addProduct.name,
        description: addProduct.description,
        type: type,
        categoryId: addProduct.categoryId,
        subCategoryId: addProduct.subCategoryId === "" ? null : addProduct?.subCategoryId,
        price: addProduct.price === "" ? "0" : addProduct.price,
        discount: addProduct.discount === "" ? "0" : addProduct.discount,
        downloadLink: addProduct.downloadLink,
      };
      formData.append("product", JSON.stringify(product));
      formData.append("attributes", JSON.stringify(addProduct.attributes));
      formData.append("thumbnail", addProduct.thumbnail);
      addProduct.images.forEach((file) => {
        formData.append("images", file);
      });
      let res = await PostAPI("item/published-by/admin", formData);
      if (res?.data?.status === "1") {
        success_toaster("Product Added Sucessfully");
        // setAddProduct({
        //   name: "",
        //   description: "",
        //   type: "",
        //   price: "",
        //   categoryId: "",
        //   discount: "",
        //   downloadLink: "",
        //   attributes: [],
        //   thumbnail: "",
        //   images: [],
        // });
        setLoader(false);
      } else {
        success_toaster(res?.data?.message);
        setLoader(false);
        navigate("/addProduct");
      }
    }
  };
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title="Add New Product"
      content={
        <>
          {loader === true ? (
            <>
              <MiniLoader />
            </>
          ) : (
            <form onSubmit={addProductfunc} className="bg-white p-6 space-y-5">
              <div className="space-y-1">
                <label className={labelStyle} htmlFor="name">
                  Name
                </label>
                <input
                  value={addProduct.name}
                  onChange={onChange}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Menu Name"
                  className={inputStyle}
                />
              </div>
              <div className="space-y-1">
                <label className={labelStyle} htmlFor="description">
                  Description
                </label>
                <input
                  value={addProduct.description}
                  onChange={onChange}
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Menu Description"
                  className={inputStyle}
                />
              </div>
              <div className="flex gap-x-3">
                <div className="space-y-1 w-full">
                  <label className={labelStyle} htmlFor="type">
                    Type
                  </label>
                  <select
                    className={inputStyle}
                    onChange={(e) => setType(e.target.value)}
                    name="type"
                  >
                    <option value="pro">Pro</option>
                    <option value="free">Free</option>
                  </select>
                </div>
                <div className="space-y-1 w-full">
                  <label className={labelStyle} htmlFor="categoryId">
                    Select Category
                  </label>
                  <select
                    className={inputStyle}
                    name="categoryId"
                    value={addProduct?.categoryId}
                    onChange={onChange}
                    // onChange={(e) => {
                    //   if (addProduct.categoryId !== "1") {
                    //     setAddProduct((prevState) => ({
                    //       ...prevState,
                    //       [e.target.name]: e.target.value,
                    //       subCategoryId: "",
                    //     }));
                    //   } else {
                    //     setAddProduct((prevState) => ({
                    //       ...prevState,
                    //       [e.target.name]: e.target.value,
                    //       subCategoryId: "1",
                    //     }));
                    //   }
                    // }}
                  >
                    {data?.data?.map((data, index) => (
                      <option key={index} value={data?.id}>
                        {data?.name}
                      </option>
                    ))}
                  </select>
                </div>
                {subCatCheck?.length === 0 ? (
                  ""
                ) : (
                  <div className="space-y-1 w-full">
                    <label className={labelStyle}>Select Sub Category</label>
                    <select
                      className={inputStyle}
                      name="subCategoryId"
                      onChange={onChange}
                    >
                      <option>Select Sub Category</option>
                      {getSubCategory?.data?.data
                        ?.filter(
                          (prod) =>
                            prod?.category?.id ===
                            parseInt(
                              addProduct.categoryId === ""
                                ? 1
                                : addProduct.categoryId
                            )
                        )
                        .map((data, index) => (
                          <option key={index} value={data?.id}>
                            {data?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </div>

              <div className="space-y-1">
                <MultiSelect
                  options={attribute}
                  value={addProduct.attributes}
                  label="Choose Attribute"
                  onChange={handleAttributeChange}
                />
              </div>
              {type === "free" ? (
                ""
              ) : (
                <>
                  <div className="space-y-1">
                    <label className={labelStyle} htmlFor="price">
                      Price
                    </label>
                    <input
                      value={addProduct.price}
                      onChange={onChange}
                      type="number"
                      name="price"
                      id="price"
                      placeholder="Menu Price"
                      className={inputStyle}
                    />
                  </div>
                  <div className="space-y-1">
                    <label className={labelStyle} htmlFor="discount">
                      Discount
                    </label>
                    <input
                      value={addProduct.discount}
                      onChange={onChange}
                      type="number"
                      name="discount"
                      id="discount"
                      placeholder="Menu Discount"
                      className={inputStyle}
                    />
                  </div>
                </>
              )}

              <div className="space-y-1">
                <label className={labelStyle} htmlFor="downloadLink">
                  Download Link
                </label>
                <input
                  value={addProduct.downloadLink}
                  onChange={onChange}
                  type="text"
                  name="downloadLink"
                  id="downloadLink"
                  placeholder="Downlaod Link"
                  className={inputStyle}
                />
              </div>
              <div className="space-y-1">
                <label className={labelStyle} htmlFor="thumbnail">
                  Thumbnail
                </label>
                <input
                  onChange={(e) =>
                    setAddProduct({
                      ...addProduct,
                      [e.target.name]: e.target.files[0],
                    })
                  }
                  type="file"
                  name="thumbnail"
                  id="thumbnail"
                  placeholder="Thumbnail"
                  className={inputStyle}
                />
              </div>
              <div className="space-y-1">
                <label className={labelStyle} htmlFor="images">
                  Images
                </label>
                <input
                  onChange={(e) =>
                    setAddProduct({
                      ...addProduct,
                      [e.target.name]: [...e.target.files],
                    })
                  }
                  type="file"
                  name="images"
                  id="images"
                  placeholder="images"
                  className={inputStyle}
                  multiple={true}
                />
              </div>
              <button className="bg-green-400 px-6 py-2 mt-5" type="submit">
                Submit
              </button>
            </form>
          )}
        </>
      }
    />
  );
}
