import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import GetAPI from "../../utilities/GetAPI";
import { PostAPI } from "../../utilities/PostAPI";
import { PutAPI } from "../../utilities/PutAPI";
import { DeleteAPI } from "../../utilities/DelAPI";
import { active, block } from "../../utilities/CustomStyles";
import MyDataTable from "../../components/MyDataTable";
import Loader, { MiniLoader } from "../../components/Loader";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../utilities/Toaster";
import AddButton, {
  DTDel,
  DTEdit,
  ModalButtons,
} from "../../utilities/Buttons";
import { inputStyle, labelStyle } from "../../utilities/Input";
import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";

export default function SubCategories() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("loginStatus")) {
      localStorage.removeItem("loginStatus");
      navigate("/login");
    }
  }, [navigate]);
  const { data, reFetch } = GetAPI("category/sub/view");
  const categories = GetAPI("category/view");
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [addMenu, setAddMenu] = useState({
    name: "",
    category: "",
  });
  const [updateMenu, setUpdateMenu] = useState({
    updateName: "",
    id: "",
  });
  const [addModal, setAddModal] = useState(false);
  const closeAddModal = () => {
    setAddModal(false);
    setAddMenu({
      name: "",
    });
  };
  const [updateModal, setUpdateModal] = useState(false);
  const closeUpdateModal = () => {
    setUpdateModal(false);
    setUpdateMenu({
      updateName: "",
      id: "",
    });
  };
  const onChange = (e) => {
    setAddMenu({ ...addMenu, [e.target.name]: e.target.value });
  };
  const onChange2 = (e) => {
    setUpdateMenu({ ...updateMenu, [e.target.name]: e.target.value });
  };
  const addMenuFunc = async (e) => {
    e.preventDefault();
    if (addMenu.name === "") {
      info_toaster("Please enter your Category's Title");
    } else {
      setLoader(true);
      let res = await PostAPI("category/sub/create", {
        name: addMenu.name,
        categoryId: addMenu.category,
      });
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.mesage);
        setAddModal(false);
        setAddMenu({
          name: "",
        });
      } else {
        setLoader(false);
        error_toaster(res?.data?.mesage);
      }
    }
  };
  const updateMenuFunc = async (e) => {
    e.preventDefault();
    if (updateMenu.updateName === "") {
      info_toaster("Please enter your UpdateName");
    } else {
      setLoader(true);
      let res = await PutAPI(`category/sub/edit/${updateMenu.id}`, {
        name: updateMenu.updateName,
      });
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.mesage);
        setUpdateModal(false);
        setUpdateMenu({
          updateName: "",
          id: "",
        });
      } else {
        setLoader(false);
        error_toaster(res?.data?.mesage);
      }
    }
  };
  const changeMenuFunc = async (id) => {
    setDisabled(true);
    let res = await PutAPI(`category/sub/change-status/${id}`);
    if (res?.data?.status === "1") {
      reFetch();
      success_toaster(res?.data?.mesage);
      setDisabled(false);
    } else {
      error_toaster(res?.data?.mesage);
      setDisabled(false);
    }
  };
  const deleteMenuFunc = async (id) => {
    setDisabled(true);
    let res = await DeleteAPI(`category/sub/delete/${id}`);
    if (res?.data?.status === "1") {
      reFetch();
      success_toaster(res?.data?.mesage);
      setDisabled(false);
    } else {
      error_toaster(res?.data?.mesage);
      setDisabled(false);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: "Title",
      selector: (row) => row.name,
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  data?.data?.map((dis, index) => {
    return datas.push({
      id: index + 1,
      name: dis?.name,
      slug: dis?.slug,
      status:
        dis?.status === "active" ? (
          <button
            onClick={() => changeMenuFunc(dis?.id)}
            disabled={disabled}
            className={active}
          >
            Active
          </button>
        ) : (
          <button
            onClick={() => changeMenuFunc(dis?.id)}
            disabled={disabled}
            className={block}
          >
            Inactive
          </button>
        ),
      action: (
        <div className="flex gap-x-2">
          <DTEdit
            edit={() => {
              setUpdateModal(true);
              setUpdateMenu({
                updateName: dis?.name,
                id: dis?.id,
              });
            }}
          />
          <DTDel del={() => deleteMenuFunc(dis?.id)} disabled={disabled} />
        </div>
      ),
    });
  });
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title="Sub Category"
      content={
        <>
          <Modal onClose={closeAddModal} isOpen={addModal} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">Add Sub Category</h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-[160px]">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="h-40 space-y-3">
                      <div className="space-y-1 w-full">
                        <label className={labelStyle} htmlFor="type">
                          Main Category
                        </label>
                        <select
                          className={inputStyle}
                          onChange={onChange}
                          name="category"

                        >
                         {categories?.data?.data.map((data , index) =>(
                          <option key={index} value={data.id}>{data.name}</option>
                         ))}
                        </select>
                      </div>
                      <div>
                        <label className={labelStyle} htmlFor="name">
                          New Sub Category
                        </label>
                        <input
                          value={addMenu.name}
                          onChange={onChange}
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Category Name"
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text="Add"
                    close={closeAddModal}
                    action={addMenuFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <Modal
            onClose={closeUpdateModal}
            isOpen={updateModal}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">Update Category</h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-[160px]">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="h-40">
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="updateName">
                          Title
                        </label>
                        <input
                          value={updateMenu.updateName}
                          onChange={onChange2}
                          type="text"
                          name="updateName"
                          id="updateName"
                          placeholder="Category Name"
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text="Update"
                    close={closeUpdateModal}
                    action={updateMenuFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <section className="space-y-3">
            <div className="flex justify-end">
              <AddButton modal={setAddModal} text="Category" />
            </div>
            <MyDataTable columns={columns} data={datas} dependancy={data} />
          </section>
        </>
      }
    />
  );
}
