import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import HomeCard from "../../components/HomeCard";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("loginStatus")) {
      localStorage.removeItem("loginStatus");
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Layout
      title="Dashboard"
      content={
        <section className="grid gap-y-12">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-5">
            <HomeCard
              icon="1"
              title="All Users"
              quantity={
                data?.data?.numOfUsers ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="2"
              title="All Warehouses"
              quantity={
                data?.data?.numOfWarehouses ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="3"
              title="All Drivers"
              quantity={
                data?.data?.numOfDrivers ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="4"
              title="All Orders"
              quantity={
                data?.data?.numOfBookings ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="5"
              title="Total Earnings"
              quantity={
                data?.data?.earnings ? (
                  data?.data?.currencyUnit + data?.data?.earnings
                ) : (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="6"
              title="Available Balance"
              quantity={
                data?.data?.balance ? (
                  data?.data?.currencyUnit + data?.data?.balance
                ) : (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="7"
              title="Driver's Earnings"
              quantity={
                data?.data?.currencyUnit + data?.data?.driverEarnings ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="8"
              title="Today's Earnings"
              quantity={
                data?.data?.currencyUnit + data?.data?.todayEarnings ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
          </div>
          {/* <div
            className={`${
              dark ? "bg-themeBlack2" : "bg-white"
            } flex flex-col gap-y-4 rounded-lg px-5 py-8`}
          >
            <h2
              className={`font-bold text-xl ${
                dark ? "text-white" : "text-themePurple"
              }`}
            >
              Recent Activities
            </h2>
            <hr />
            <div className="flex items-center gap-x-3">
              <img
                src={`${imgURL}logo-4${dark ? "D" : ""}.webp`}
                alt="logo"
                className="w-10"
              />
              <span
                className={`font-bold text-base ${
                  dark ? "text-themeWhite" : "text-black text-opacity-40"
                }`}
              >
                1 New Order is created
              </span>
            </div>
            <hr />
            <div className="flex items-center gap-x-3">
              <img
                src={`${imgURL}logo-1${dark ? "D" : ""}.webp`}
                alt="logo"
                className="w-10"
              />
              <span
                className={`font-bold text-base ${
                  dark ? "text-themeWhite" : "text-black text-opacity-40"
                }`}
              >
                2 New users Login the PPS
              </span>
            </div>
            <hr />
            <div className="flex items-center gap-x-3">
              <img
                src={`${imgURL}logo-6${dark ? "D" : ""}.webp`}
                alt="logo"
                className="w-10"
              />
              <span
                className={`font-bold text-base ${
                  dark ? "text-themeWhite" : "text-black text-opacity-40"
                }`}
              >
                $25 Received by you
              </span>
            </div>
          </div> */}
        </section>
      }
    />
  );
}
