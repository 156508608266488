import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import MyDataTable from "../../components/MyDataTable";
import GetAPI from "../../utilities/GetAPI";
import { Link, useNavigate } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import { ModeContext } from "../../App";
import { DTDel } from "../../utilities/Buttons";
import { DeleteAPI } from "../../utilities/DelAPI";
import { error_toaster, success_toaster } from "../../utilities/Toaster";

export default function Products() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("loginStatus")) {
      localStorage.removeItem("loginStatus");
      navigate("/login");
    }
  }, [navigate]);
  const { dark } = useContext(ModeContext);
  const [disabled, setDisabled] = useState(false);
  const editFunc = (dis) => {
    navigate(`/editProduct/${dis?.slug}`, {
      state: { prodData: dis },
    });
  };

  const [category, setCategory] = useState(
    localStorage.getItem("prevSearchOption") || "0"
  );
  console.log("🚀 ~ Products ~ category:", category);
  const { data, reFetch } = GetAPI("item/view-all");
  const categories = GetAPI("category/view");

  const deleteMenuFunc = async (id) => {
    setDisabled(true);
    let res = await DeleteAPI(`item/delete/${id}`);
    if (res?.data?.status === "1") {
      reFetch();
      success_toaster(res?.data?.mesage);
      setDisabled(false);
    } else {
      error_toaster(res?.data?.mesage);
      setDisabled(false);
    }
  };
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: "Title",
      selector: (row) => row.name,
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  const filteredData =
    parseInt(category) === 0
      ? data?.data
      : data?.data?.filter((prod) => prod?.category?.id === parseInt(category));
  filteredData?.map((dis, index) => {
    return datas.push({
      id: index + 1,
      name: dis?.name,
      slug: dis?.slug,
      status:
        dis?.status === "active" ? (
          <button>Active</button>
        ) : (
          <button>Inactive</button>
        ),
      action: (
        <div className="flex gap-x-3">
          <button
            onClick={() => editFunc(dis)}
            className={`${
              dark
                ? "text-white border-white hover:bg-white hover:text-themePurple"
                : "text-themePurple border-themePurple hover:bg-themePurple hover:text-white"
            } p-1 rounded-fullest border-2`}
          >
            <MdModeEditOutline />
          </button>

          <DTDel del={() => deleteMenuFunc(dis?.id)} disabled={disabled} />
        </div>
      ),
    });
  });
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title="Products"
      content={
        <>
          <section className="space-y-3">
            <div className="flex items-center justify-end gap-x-3">
              <div>
                <select
                  className="w-full resize-none font-normal text-base text-black rounded py-2.5 px-4 bg-gray-300 border placeholder:text-black placeholder:text-opacity-60 focus:outline-none"
                  name="category"
                  onChange={(e) => {
                    setCategory(e.target.value);
                    localStorage.setItem("prevSearchOption", e.target.value);
                  }}
                  defaultValue={localStorage.getItem("prevSearchOption") || "0"}
                >
                  <option value="0">All</option>
                  {categories?.data?.data?.map((data, index) => (
                    <option key={index} value={data?.id}>
                      {data?.name}
                    </option>
                  ))}
                </select>
              </div>
              <Link
                to={"/addProduct"}
                className="py-2.5 px-12 rounded bg-themePurple font-medium text-base text-white border border-themePurple hover:bg-transparent hover:text-themePurple cursor-pointer"
              >
                Add New Product
              </Link>
            </div>
            <MyDataTable columns={columns} data={datas} />
          </section>
        </>
      }
    />
  );
}
