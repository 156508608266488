import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import GetAPI from "../../utilities/GetAPI";
import { PostAPI } from "../../utilities/PostAPI";
import { inputStyle, labelStyle } from "../../utilities/Input";
import { error_toaster, success_toaster } from "../../utilities/Toaster";
import Loader, { MiniLoader } from "../../components/Loader";
import { MultiSelect } from "chakra-multiselect";
import { BASE_URL } from "../../utilities/URL";
import { PhotoView } from "react-photo-view";

export default function EditProducts() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("loginStatus")) {
      localStorage.removeItem("loginStatus");
      navigate("/login");
    }
  }, [navigate]);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const prodData = location?.state?.prodData;
  const { data } = GetAPI("category/view");
  const getSubCategory = GetAPI("category/sub/view");
  const [type, setType] = useState(location?.state?.prodData?.type);
  const attributeData = GetAPI("attribute/view");
  const attribute = [];
  attributeData.data?.data?.map((item, index) =>
    attribute.push({
      // id: item.id,
      arttribute: item.name,
      value: item.name,
    })
  );
  console.log("attribute", attribute);
  console.log(prodData.itemAttributes);

  const [editProduct, setEditProduct] = useState({
    productId: prodData?.id,
    name: prodData?.name,
    description: prodData?.description,
    price: prodData?.price,
    categoryId: prodData?.category?.id || "1",
    subCategoryId: prodData?.subCategory?.id || "1",
    discount: prodData?.discount,
    downloadLink: prodData?.downloadLink,
    type: prodData?.type,
    attributes: prodData.itemAttributes,
    thumbnail: "",
    images: [],
  });
  const handleAttributeChange = (selectedAttributes) => {
    setEditProduct((prevProduct) => ({
      ...prevProduct,
      attributes: selectedAttributes,
    }));
  };

  const onChange = (e) => {
    setEditProduct({ ...editProduct, [e.target.name]: e.target.value });
  };
  const editProductfunc = async (e) => {
    e.preventDefault();
    if (editProduct.name === "") {
      error_toaster("Please enter Product Name");
    } else if (editProduct.description === "") {
      error_toaster("Please enter Product Description");
    } else if (type === "") {
      error_toaster("Please Select Type");
    } else if (editProduct.categoryId === "") {
      error_toaster("Please enter Product category");
    } else if (editProduct.price === "") {
      error_toaster("Please Enter Product Price");
    } else if (editProduct.downloadLink === "") {
      error_toaster("Please enter Product Link");
    } else {
      setLoader(true);
      const formData = new FormData();
      const product = {
        productId: prodData?.id,
        name: editProduct.name,
        description: editProduct.description,
        type: type,
        categoryId: editProduct.categoryId,
        subCategoryId:
          editProduct.subCategoryId === "" ? null : editProduct?.subCategoryId,
        price: (editProduct.price === "" || type === "free") ? "0" : editProduct.price,
        discount: editProduct.discount === "" ? "0" : editProduct.discount,
        downloadLink: editProduct.downloadLink,
      };

      formData.append("product", JSON.stringify(product));
      formData.append("attributes", JSON.stringify(editProduct.attributes));
      formData.append("thumbnail", editProduct.thumbnail);
      editProduct.images.forEach((file) => {
        formData.append("images", file);
      });

      let res = await PostAPI("item/update-by/admin", formData);
      console.log("🚀 ~ editProductfunc ~ res:", res);
      if (res?.data?.status === "1") {
        success_toaster("Product Updated Sucessfully");
        setEditProduct({
          name: "",
          description: "",
          type: "",
          price: "",
          categoryId: "",
          discount: "",
          downloadLink: "",
          attributes: [],
          thumbnail: "",
          images: [],
        });
        setLoader(false);
        navigate("/products");
      } else {
        success_toaster(res?.data?.message);
        success_toaster("Something Went Wrong");
        setLoader(false);
      }
    }
  };
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title="Edit Product"
      content={
        <>
          {loader === true ? (
            <>
              <MiniLoader />
            </>
          ) : (
            <form onSubmit={editProductfunc} className="bg-white p-6 space-y-5">
              <div className="space-y-1">
                <label className={labelStyle} htmlFor="name">
                  Name
                </label>
                <input
                  value={editProduct?.name}
                  onChange={onChange}
                  type="text"
                  name="name"
                  id="name"
                  placeholder={editProduct?.name}
                  className={inputStyle}
                />
              </div>
              <div className="space-y-1">
                <label className={labelStyle} htmlFor="description">
                  Description
                </label>
                <input
                  value={editProduct.description}
                  onChange={onChange}
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Menu Description"
                  className={inputStyle}
                />
              </div>
              <div className="flex gap-x-3">
                <div className="space-y-1 w-full">
                  <label className={labelStyle} htmlFor="type">
                    Type
                  </label>
                  <select
                    className={inputStyle}
                    onChange={(e) => setType(e.target.value)}
                    name="type"
                    value={type}
                  >
                    <option value="pro">Pro</option>
                    <option value="free">Free</option>
                  </select>
                </div>
                <div className="space-y-1 w-full">
                  <label className={labelStyle}>Select Category</label>
                  <select
                    className={inputStyle}
                    name="categoryId"
                    // onChange={(e) => setCategory(e.target.value)}
                    onChange={onChange}
                    value={editProduct.categoryId}
                  >
                    {data?.data?.map((data, index) => (
                      <option
                        key={index}
                        value={data?.id}
                        defaultValue={data.id === editProduct.categoryId}
                      >
                        {data?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1 w-full">
                  <label className={labelStyle}>Select Sub Category</label>
                  <select
                    className={inputStyle}
                    name="subCategoryId"
                    onChange={onChange}
                    value={editProduct?.subCategoryId}
                  >
                    {getSubCategory?.data?.data
                      ?.filter(
                        (prod) =>
                          prod?.category?.id ===
                          parseInt(
                            editProduct.categoryId === ""
                              ? 1
                              : editProduct.categoryId
                          )
                      )
                      .map((data, index) => (
                        <option
                          key={index}
                          value={data?.id}
                          defaultValue={data.id === editProduct.subCategoryId}
                        >
                          {data?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="space-y-1">
                <MultiSelect
                  options={attribute}
                  value={editProduct?.attributes}
                  label="Choose Attribute"
                  onChange={handleAttributeChange}
                />
              </div>
              {type === "free" ? (
                ""
              ) : (
                <>
                  <div className="space-y-1">
                    <label className={labelStyle} htmlFor="price">
                      Price
                    </label>
                    <input
                      value={editProduct.price}
                      onChange={onChange}
                      type="number"
                      name="price"
                      id="price"
                      placeholder="Menu Price"
                      className={inputStyle}
                    />
                  </div>
                  <div className="space-y-1">
                    <label className={labelStyle} htmlFor="discount">
                      Discount
                    </label>
                    <input
                      value={editProduct.discount}
                      onChange={onChange}
                      type="number"
                      name="discount"
                      id="discount"
                      placeholder="Menu Discount"
                      className={inputStyle}
                    />
                  </div>
                </>
              )}

              <div className="space-y-1">
                <label className={labelStyle} htmlFor="downloadLink">
                  Download Link
                </label>
                <input
                  value={editProduct.downloadLink}
                  onChange={onChange}
                  type="text"
                  name="downloadLink"
                  id="downloadLink"
                  placeholder="Downlaod Link"
                  className={inputStyle}
                />
              </div>
              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <div className="space-y-1">
                    <label className={labelStyle} htmlFor="thumbnail">
                      Thumbnail
                    </label>
                    <input
                      onChange={(e) =>
                        setEditProduct({
                          ...editProduct,
                          [e.target.name]: e.target.files[0],
                        })
                      }
                      type="file"
                      name="thumbnail"
                      id="thumbnail"
                      placeholder="Thumbnail"
                      className={inputStyle}
                    />
                  </div>
                  <div className="space-y-1">
                    <label className={labelStyle} htmlFor="images">
                      Images
                    </label>
                    <input
                      onChange={(e) =>
                        setEditProduct({
                          ...editProduct,
                          [e.target.name]: [...e.target.files],
                        })
                      }
                      type="file"
                      name="images"
                      id="images"
                      placeholder="images"
                      className={inputStyle}
                      multiple={true}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <PhotoView src={`${BASE_URL}${prodData?.thumbnail}`}>
                    <img
                      className="w-60 h-full object-contain m-auto rounded-xl cursor-pointer"
                      src={`${BASE_URL}${prodData?.thumbnail}`}
                      alt="Thumbnail"
                    />
                  </PhotoView>
                </div>
              </div>

              <button className="bg-green-400 px-6 py-2 mt-5" type="submit">
                Submit
              </button>
            </form>
          )}
        </>
      }
    />
  );
}
