  import React, { useEffect } from "react";
  import GetAPI from "../../utilities/GetAPI";
  import MyDataTable from "../../components/MyDataTable";
  import Loader from "../../components/Loader";
  import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";
  

export default function Users() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("loginStatus")) {
      localStorage.removeItem("loginStatus");
      navigate("/login");
    }
  }, [navigate]);
    const { data } = GetAPI("user/view-customers");
    const columns = [
      {
        name: "#",
        selector: (row) => row.id,
      },
      {
        name: "Title",
        selector: (row) => row.name,
      },
      {
        name: "Email",
        selector: (row) => row.email,
      },
      {
        name: "User Type",
        selector: (row) => row.userType,
      },
      {
        name: "Stripe Customer Id",
        selector: (row) => row.stripeCustomerId,
      },
    ];
    const datas = [];
    data?.data?.map((dis, index) => {
      return datas.push({
        id: index + 1,
        name: `${dis?.firstName}${dis?.lastName ? ' ' + dis.lastName : ''}`,
        email: dis?.email,
        userType:dis?.userType,
        stripeCustomerId: dis?.stripeCustomerId ? dis?.stripeCustomerId : "Stripe not attach yet",
      });
    });
    return data.length === 0 ? (
      <Loader />
    ) : (
      <Layout
        title="Users List"
        content={
          <>
            <section className="space-y-3">
              <MyDataTable columns={columns} data={datas} dependancy={data} />
            </section>
          </>
        }
      />
    );
  }
  
