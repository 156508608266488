import React, { useContext } from "react";
import { AiOutlinePoweroff } from "react-icons/ai";
import { GiModernCity } from "react-icons/gi";
import { MdLocationCity } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { VscSymbolStructure } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
import { ModeContext } from "../App";
import { info_toaster } from "../utilities/Toaster";
import { imgURL } from "../utilities/URL";
import { LayoutContext } from "./Layout";
import ListHead from "./ListHead";
import ListItem from "./ListItem";

export default function LeftBar() {
  const navigate = useNavigate();
  const { expand, setLoader } = useContext(LayoutContext);
  const { dark } = useContext(ModeContext);
  const logoutFunc = (e) => {
    e.preventDefault();
    setLoader(true);
    setTimeout(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loginStatus");
      localStorage.removeItem("name");
      localStorage.removeItem("otpId");
      localStorage.removeItem("userId");
      navigate("/login");
      info_toaster("Successfully Logged out!");
    }, 400);
  };
  return (
    <div
      className={`w-64 fixed h-full py-7 pl-3 pr-3 ${
        expand ? "hidden" : "flex"
      } ${
        dark ? "bg-themeBlack border-white" : "bg-white border-black"
      } flex-col justify-between border-r border-opacity-10`}
    >
      <div>
        <Link to={"/"}>
          <img
            src={`${imgURL}${dark ? "logo" : "logo"}.gif`}
            alt="logo"
            className="object-contain bg-themePurple rounded-lg mb-4"
          />
        </Link>
      </div>
      <ul className="flex flex-col gap-y-5 h-3/4 overflow-auto sidebar xl:pr-2 pr-1">
        <div className="space-y-3">
          <div className="space-y-1">
            <ListHead title="Dashboard" />
          </div>
          <ListItem to={"/"} Icon={RiDashboardFill} title="Home" />
        </div>
        <div className="space-y-3">
          <div className="space-y-1">
            <ListHead title="Main Menu" />
          </div>
          <ListItem to={"/users"} Icon={GiModernCity} title="Users" />
          <ListItem to={"/menu"} Icon={GiModernCity} title="Menu" />
          <ListItem
            to={"/attributes"}
            Icon={MdLocationCity}
            title="Attributes"
          />
          <ListItem
            to={"/categories"}
            Icon={VscSymbolStructure}
            title="Categories"
          />
          <ListItem
            to={"/subCategories"}
            Icon={VscSymbolStructure}
            title="Sub Categories"
          />
          <ListItem
            to={"/addProduct"}
            Icon={VscSymbolStructure}
            title="Add Product"
          />
          <ListItem
            to={"/products"}
            Icon={VscSymbolStructure}
            title="Edit Products"
          />
        </div>
      </ul>
      <ul className="xl:pr-2 pr-1 relative">
        <li>
          <button
            onClick={logoutFunc}
            className={`w-full flex items-center gap-x-4 px-4 py-2 rounded ${
              dark ? "text-white" : "text-black text-opacity-40"
            } font-medium xl:text-lg text-base focus:bg-themePurple focus:text-themeOrange focus:text-opacity-100`}
          >
            <AiOutlinePoweroff size={24} />
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </div>
  );
}
